<template>
	<div class="masters">
		<SubHeader title="ODO Masters" subtitle="ODOマスターズ" />

		<div class="container">
			<ul class="nav nav-pills mb-3" id="myTab" role="tablist">
				<li class="nav-item">
					<a class="nav-link" href="/masters/2024/"> 2024（2023年度） </a>
				</li>
				<li class="nav-item">
					<a class="nav-link active" href="javascript:void(0);">
						2023（2022年度）
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="/masters/2020/"> 2020（2019年度） </a>
				</li>
			</ul>

			<div class="row justify-content-center">
				<div class="col-lg-12">
					<h2>ODOマスターズ2023（2022年度）</h2>
					<h5>開催：2023年7月17日（月祝）、会場：住吉大社（吉祥殿）</h5>
				</div>
			</div>

			<div class="row d-flex align-items-end mb-4">
				<div class="col-6 offset-3 text-center">
					<p>
						<span class="rank_winner"><i class="fas fa-medal"></i>Winner</span>
						<img
							src="/images/masters/2023/odomasters_02.jpg"
							class="img-fluid"
							@click="openGallery(0)"
						/>
						<span class="name">餅木 尚之</span>
					</p>
				</div>
				<div class="col-4 text-center">
					<p>
						<span class="rank_2nd"><i class="fas fa-medal"></i>2nd</span>
						<img
							src="/images/masters/2023/odomasters_03.jpg"
							class="img-fluid"
							@click="openGallery(1)"
						/>
						<span class="name">小村 亮</span>
					</p>
				</div>
				<div class="col-4 text-center">
					<p>
						<span class="rank_3rd"><i class="fas fa-medal"></i>3rd</span>
						<img
							src="/images/masters/2023/odomasters_04.jpg"
							class="img-fluid"
							@click="openGallery(2)"
						/>
						<span class="name">倉戸 亮</span>
					</p>
				</div>
				<div class="col-4 text-center">
					<p>
						<span class="rank_3rd">4th</span>
						<img
							src="/images/masters/2023/odomasters_05.jpg"
							class="img-fluid"
							@click="openGallery(3)"
						/>
						<span class="name">西岡 健太郎</span>
					</p>
				</div>
			</div>

			<h4>集合写真＆決勝トーナメント表</h4>
			<div class="row mb-4">
				<div class="col-5 text-center offset-1">
					<p>
						<span class="rank">集合写真</span>
						<img
							src="/images/masters/2023/odomasters_06.jpg"
							class="img-fluid"
							@click="openGallery(4)"
						/>
					</p>
				</div>
				<div class="col-5 text-center">
					<p>
						<span class="rank">決勝トーナメント表</span>
						<img
							src="/images/masters/2023/odomasters_07.jpg"
							class="img-fluid"
							@click="openGallery(5)"
						/>
					</p>
				</div>
			</div>

			<h4>エントリー</h4>
			<div class="row">
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/2.tanida.jpg"
							class="img-fluid"
						/>
						<span class="name">谷田 孝夫</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/3.odachi.jpg"
							class="img-fluid"
						/>
						<span class="name">大達 浩司</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/5.sotoosa.jpg"
							class="img-fluid"
						/>
						<span class="name">外筬 久人</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/8.nishioka.jpg"
							class="img-fluid"
						/>
						<span class="name">西岡 健太郎</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/10.kurato.jpg"
							class="img-fluid"
						/>
						<span class="name">倉戸 亮</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/12.kurokawa.jpg"
							class="img-fluid"
						/>
						<span class="name">黒川 智成</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/16.mochiki.jpg"
							class="img-fluid"
						/>
						<span class="name">餅木 尚之</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/17.den.jpg"
							class="img-fluid"
						/>
						<span class="name">田 達輝</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/20.komura.jpg"
							class="img-fluid"
						/>
						<span class="name">小村 亮</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/21.imoto.jpg"
							class="img-fluid"
						/>
						<span class="name">井本 隆之</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/22.hayashi.jpg"
							class="img-fluid"
						/>
						<span class="name">林 和義</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/23.narita.jpg"
							class="img-fluid"
						/>
						<span class="name">成田 優斗</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/24.matsumoto.jpg"
							class="img-fluid"
						/>
						<span class="name">松本 康寿</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/24.yanase.jpg"
							class="img-fluid"
						/>
						<span class="name">柳瀬 将一</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/29.wada.jpg"
							class="img-fluid"
						/>
						<span class="name">和田 勝</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/30.nishimoto.jpg"
							class="img-fluid"
						/>
						<span class="name">西本 陽一</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/30.usuzawa.jpg"
							class="img-fluid"
						/>
						<span class="name">臼沢 晋</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/32.hayashi.jpg"
							class="img-fluid"
						/>
						<span class="name">林 成光</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/33.awajiya.jpg"
							class="img-fluid"
						/>
						<span class="name">淡路屋 湧也</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/35.sunakawa.jpg"
							class="img-fluid"
						/>
						<span class="name">砂川 祥輝</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/37.imura.jpg"
							class="img-fluid"
						/>
						<span class="name">井村 有希</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/38.kogama.jpg"
							class="img-fluid"
						/>
						<span class="name">鈳 孝子</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/39.kimura.jpg"
							class="img-fluid"
						/>
						<span class="name">木村 圭吾</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/39.tatara.jpg"
							class="img-fluid"
						/>
						<span class="name">多田良 宏栄</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/42.iwatani.jpg"
							class="img-fluid"
						/>
						<span class="name">岩谷 由人</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/43.tomita.jpg"
							class="img-fluid"
						/>
						<span class="name">冨田 圭佑</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/43.morimoto.jpg"
							class="img-fluid"
						/>
						<span class="name">森本 啓五</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/47.fukagawa.jpg"
							class="img-fluid"
						/>
						<span class="name">深川 晃平</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/47.morioka.jpg"
							class="img-fluid"
						/>
						<span class="name">森岡 康之</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/49.tahara.jpg"
							class="img-fluid"
						/>
						<span class="name">田原 駿也</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/noimage.jpg"
							class="img-fluid"
						/>
						<span class="name">磯和 勇希</span>
					</p>
				</div>
				<div class="col-lg-2 col-3 text-center">
					<p>
						<img
							src="/images/masters/2023/entries/noimage.jpg"
							class="img-fluid"
						/>
						<span class="name">和田 光生</span>
					</p>
				</div>
			</div>

			<LightBox
				ref="lightbox"
				:media="media"
				:show-light-box="false"
				:show-caption="true"
			></LightBox>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import SubHeader from "@/components/common/SubHeader.vue";
//import VueLazyLoad from "vue-lazyload";
import LightBox from "vue-image-lightbox";
require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

export default {
	name: "masters",
	components: {
		SubHeader,
		LightBox,
	},
	data() {
		return {
			media: [
				{
					thumb: "/images/masters/2023/odomasters_02.jpg",
					src: "/images/masters/2023/odomasters_02.jpg",
					caption: "餅木 尚之",
				},
				{
					thumb: "/images/masters/2023/odomasters_03.jpg",
					src: "/images/masters/2023/odomasters_03.jpg",
					caption: "小村 亮",
				},
				{
					thumb: "/images/masters/2023/odomasters_04.jpg",
					src: "/images/masters/2023/odomasters_04.jpg",
					caption: "倉戸 亮",
				},
				{
					thumb: "/images/masters/2023/odomasters_05.jpg",
					src: "/images/masters/2023/odomasters_05.jpg",
					caption: "西岡 健太郎",
				},
				{
					thumb: "/images/masters/2023/odomasters_06.jpg",
					src: "/images/masters/2023/odomasters_06.jpg",
					caption: "集合写真",
				},
				{
					thumb: "/images/masters/2023/odomasters_07.jpg",
					src: "/images/masters/2023/odomasters_07.jpg",
					caption: "決勝トーナメント表",
				},
			],
		};
	},
	methods: {
		openGallery(index) {
			this.$refs.lightbox.showImage(index);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
	font-size: 1.6rem;
	margin-bottom: 1rem;
}
h4 {
	border-bottom: solid 3px #333;
	padding-bottom: 5px;
	margin-bottom: 2rem;
	position: relative;
	&:before {
		content: "";
		width: 3rem;
		height: 3px;
		background-color: $primary-color;
		display: block;
		position: absolute;
		left: 0;
		bottom: -3px;
	}
}
.container {
	padding: 4rem 1rem;
	text-align: left;
}
.rank,
.rank_winner,
.rank_2nd,
.rank_3rd,
.name {
	color: #333;
}
.rank_winner {
	font-size: 2rem;
	i {
		color: #dbb400;
	}
}
.rank_2nd {
	font-size: 1.4rem;
	i {
		color: #9fa0a0;
	}
}
.rank_3rd {
	font-size: 1.4rem;
	i {
		color: #c47022;
	}
}
i {
	margin-right: 0.6rem;
}
</style>
